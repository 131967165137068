@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@800;900&display=swap");

.main_container_soon {
  width: 100%;
  min-height: 100wh;
  padding: 1em 0;
  /* text-align: justify; */
}

.main_container_soon h4{
  font-size: 40px;
  padding: 10px;
  font-weight: 600;
  color: var(--dark-color);
  font-family: "Raleway", sans-serif;
 text-align: center;
 font-family: DM Sans,sans-serif;
 margin-bottom: 30px;
}

.main_box_soon {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  padding: 2px 50px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.headings h5 {
  font-size: 36px;
  font-weight: 600;
  color: var(--primary-color);
  font-family: "Raleway", sans-serif;
  font-family: DM Sans,sans-serif;
  text-align: left;
  margin-left: 25px;
  margin-bottom: 10px;
}

.left_section_box_soon p {
  font-size: 20px;
  font-weight: 400;
  color: var(--para-color);
  margin-bottom: 50px;
  /* padding: 0 100px; */
}

.right_section_box_soon img {
  width: 380px;
  object-fit: cover;
  /* margin-bottom: 20px; */
  /* margin: 0 100px; */
}

#bg{
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
  padding-bottom: 50px;
}

#open_for_collab{
  width: 500px;
  background-color: #fff;
  /* border: 1px solid #ccc;
  border-radius: 15px; */
  padding: 20px 0;
  /* margin-left: 900px; */
}

#open_for_collab p{
  padding: 20px 30px;
  margin: 0;
}

#open_for_collab h5{
  margin: 0;
  padding-left: 25px;
}

.how_collab p{
  font-size: 20px;
  font-weight: 400;
  color: var(--para-color);
  margin-bottom: 15px;
  /* margin-right: 80px; */
  /* margin-left: 70px; */
}
#how_collab_img{
  height: 621px; 
  width: 560px;
}

/* .icons_container_footer {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 1px;
  justify-content: center;
} */

/* .icon1_footer {
  font-size: 40px;
  color: var(--white-color);
  color: rgb(180, 119, 237);
  color: var(--primary-color);
  transition: 0.2s all ease;
  margin-left: 10px;
  margin-right: 10px;
} */

/* .icon1_footer:hover,
.icon2_footer:hover,
.icon3_footer:hover,
.icon4_footer:hover,
.icon5_footer:hover {
  color: var(--background-secondary);
} */

/* .icon2_footer {
  font-size: 46px;
  color: var(--white-color);
  color: rgb(180, 119, 237);
  margin-left: 10px;
  margin-right: 10px;
} */

/* .icon3_footer {
  font-size: 46px;
  color: var(--white-color);
  color: rgb(180, 119, 237);
  color: var(--primary-color);
  margin-left: -3px;
  margin-left: 10px;
  margin-right: 10px;
} */

/* .icon4_footer {
  font-size: 52px;
  color: var(--white-color);
  color: rgb(180, 119, 237);
  color: var(--primary-color);
  margin-left: -7px;
  margin-left: 10px;
  margin-right: 10px;
} */

/* .icon5_footer {
  font-size: 44px;
  color: var(--white-color);
  color: rgb(180, 119, 237);
  color: var(--primary-color);
  margin-left: -6px;
  margin-left: 10px;
  margin-right: 10px;
} */

/* .icons{
display: flex;
flex-direction: column;
font-size: 40px;
text-align: center;
font-family: DM Sans,sans-serif;
color: var(--primary-color);
padding-top: 20px;
padding-bottom: 50px;
margin-top: 50px;
} */

@media screen and (max-width: 1200px) {
  /* .headings h5{
    margin-bottom: 100px;
    font-size: 32px;
  }
  .main_box_soon {
    flex-direction: column-reverse;
  }

  .left_section_box_soon {
    margin-top: -90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right_section_box_soon {
    margin-top: -70px;
  }

  .left_section_box_soon h4 {
    font-size: 58px;
  }

  .left_section_box_soon p {
    text-align: center;
    font-size: 25px;
  }
  .icons{
    flex-direction: column;
  }
  .icons_container_footer{
    margin-bottom: 70px;
  } */
  .right_section_box_soon img{
    margin: 0;
    width: 250px;
  }
  .left_section_box_soon h5{
    font-size: 34px;
  }
  .left_section_box_soon p{
    font-size: 16px;
  }
  .how_collab p{
    font-size: 16px;
  }
  #how_collab_img{
    width: 250px;
    height: 260px;
  }
  
}

@media screen and (max-width: 800px) {
  .headings h5{
    margin-bottom: 10px;
    font-size: 28px;
  }
  .main_box_soon {
    padding: 0 20px;
    display: block;
  }

  .right_section_box_soon {
    display: flex;
    justify-content: center;
  }

  .right_section_box_soon img {
    width: 360px;
    margin: auto;
  }

  .left_section_box_soon p {
    font-size: 16px;
  }
  .icons{
    flex-direction: column;
  }
  .icons_container_footer{
    margin-bottom: 65px;
  }
  #bg{
    padding: 50px;
    height: 450px;
  }
  .right_section_box{
    display: flex;
    justify-content: center;
  }
  #how_collab_img{
margin: auto;  }
}

@media screen and (max-width: 600px) {
  .main_box_soon {
    padding: 0;
  }
  .headings h5{
    margin-bottom: 10px;
    font-size: 28px;
  }
  #bg{
    height: 630px;
    padding: 20px;
  }
  #open_for_collab{
    width: 350px;
  }
  .icons{
    flex-direction: column;
  }
  .icons_container_footer{
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 460px) {

  .main_container_soon{
    flex-direction: column;
  }
  .headings h5{
    margin-bottom: 10px;
    font-size: 32px;
  }
  .main_box_soon{
    display: flex;
    flex-direction: column;
  }

  .right_section_box_soon {
    flex-direction: column;
  }

  .left_section_box_soon p {
    font-size: 16px;
  }
  .icons{
    flex-direction: column;
  }
  .icons_container_footer{
    margin-bottom: 55px;
  }
  #open_for_collab{
    width: 300px;
  }
  #open_for_collab p{
    padding: 5px 10px;
  }
}
