.main_container_homebanner3 {
  width: 100%;
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  background-color: var(--background-secondary);
}

.top_section_homebanner3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
}

.top_section_homebanner3 h2 {
  font-weight: 800;
  font-size: 38px;
  line-height: 133.5%;
  text-align: center;
  color: var(--primary-dark);
}

.top_section_homebanner3 p {
  width: 55%;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #3f3f3f; /*changed font-color*/
}

.bottom_section_homebanner3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 35px;
  padding: 20px 0 0 0;
}

.bottom_section_homebanner3 a {
  text-decoration: none;
}

.icon1_homebanner3 {
  font-size: 50px;
  color: var(--primary-color);
  transition: 0.2s all ease;
}

.icon1_homebanner3:hover,
.icon2_homebanner3:hover,
.icon3_homebanner3:hover,
.icon4_homebanner3:hover,
.icon5_homebanner3:hover {
  color: var(--primary-light);
}

.icon2_homebanner3 {
  font-size: 56px;
  color: var(--primary-color);
}

.icon3_homebanner3 {
  font-size: 56px;
  color: var(--primary-color);
  margin-left: -3px;
}

.icon4_homebanner3 {
  font-size: 66px;
  color: var(--primary-color);
  margin-left: -7px;
}

.icon5_homebanner3 {
  font-size: 54px;
  color: var(--primary-color);
  margin-left: -7px;
}

@media screen and (max-width: 800px) {
    .main_container_homebanner3 {
        padding: 50px 30px;
      }

  .top_section_homebanner3 h2 {
    font-size: 32px;
    line-height: 123.5%;
  }

  .top_section_homebanner3 p {
    width: 90%;
    font-size: 16px;
    line-height: 25px;
  }

  .bottom_section_homebanner3 {
    grid-gap: 30px;
  }
  

  .icon1_homebanner3 {
    font-size: 42px;
  }

  .icon2_homebanner3 {
    font-size: 46px;
  }

  .icon3_homebanner3 {
    font-size: 46px;
  }

  .icon4_homebanner3 {
    font-size: 56px;
  }

  .icon5_homebanner3 {
    font-size: 46px;
  }
}

@media screen and (max-width: 390px) {
    .main_container_homebanner3 {
        padding: 40px 20px;
    
        grid-gap: 15px;
      }
   

    .top_section_homebanner3 h2 {
        font-size: 28px;
        line-height: 113.5%;
      }
    
      .top_section_homebanner3 p {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
      }
    
      .bottom_section_homebanner3 {
        grid-gap: 20px;
        padding: 15px 0 0 0;
      }
      

      .icon1_homebanner3 {
        font-size: 33px;
      }
    
      .icon2_homebanner3 {
        font-size: 37px;
      }
    
      .icon3_homebanner3 {
        font-size: 37px;
      }
    
      .icon4_homebanner3 {
        font-size: 44px;
      }

      .icon5_homebanner3 {
        font-size: 36px;
      }
}