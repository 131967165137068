@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Quicksand:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
}

body {
    background-color: var(--white-color);
}

.light-theme {
    --primary-color: #5895FF;
    --primary-light: #70A5FF;
    --primary-lightest: #F4F7FC;
    --primary-dark: #2B313F;
    --white-color: #ffffff;
    --black-color: #000000;
    --warning-color: red;
    --para-color: #566474;
    --input-color: #1e272e;
    --input-border: #E4EBF1;
    --input-hover: #CAD7E3;   
    --input-focus: #7840f2;
    --background-secondary: #E3EAF866;
    --background-secondary-hover: #E6EAEF;
    --create-btn: #175CE6;
    --create-btn-hover: #457DED;
    --header-bg: #fff;
    --info-color-light: #616D7A;
    --info-color-dark: #68798D;
    --menu-link: #3f4358;
    --primary-background: #cbb4f8;
    --light-gray: #8A8A8A;
    --accept-color: #CDF3DE;
    --reject-color: #FFC2C2;
    --dark-color: #113DA4
}

