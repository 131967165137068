@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@800;900&display=swap");

.main_container_error {
  width: 100%;
  min-height: 100wh;
}

.main_box_error {
  width: 100%;
  display: flex;
  align-items: center;
  grid-gap: 30px;
  padding: 0px 60px;
}

.left_section_box_error h4 {
  font-size: 65px;
  font-weight: 900;
  color: var(--primary-color);
  font-family: "Raleway", sans-serif;
}

.left_section_box_error h3 {
  font-size: 50px;
  font-weight: 800;
  color: var(--black-color);
  font-family: "Raleway", sans-serif;
}

.left_section_box_error p {
  font-size: 30px;
  font-weight: 400;
  color: var(--para-color);
}

.left_section_box_error button {
  min-width: 90px;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 9px 14px;
  border-radius: 0.313rem;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

.left_section_box_error button:hover {
  background-color: var(--primary-light);
}

.right_section_box_error img {
  width: 600px;
  object-fit: cover;
}

@media screen and (max-width: 1200px) {
  .main_box_error {
    flex-direction: column-reverse;
  }

  .left_section_box_error {
    margin-top: -90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .right_section_box_error {
    margin-top: -70px;
  }

  .left_section_box_error h4 {
    font-size: 58px;
  }

  .left_section_box_error h3 {
    text-align: center;
    font-size: 40px;
  }

  .left_section_box_error p {
    text-align: center;
    font-size: 25px;
  }
}

@media screen and (max-width: 720px) {
  .main_box_error {
    padding: 0 20px;
  }

  .right_section_box_error {
    width: 100%;
  }

  .right_section_box_error img {
    width: 100%;
  }

  .left_section_box_error h4 {
    font-size: 45px;
  }

  .left_section_box_error h3 {
    font-size: 32px;
  }

  .left_section_box_error p {
    font-size: 22px;
  }
}

@media screen and (max-width: 560px) {
  .main_box_error {
    padding: 0;
  }

  .left_section_box_error {
    padding: 0 15px;
    margin-top: -70px;
  }

  .right_section_box_error {
    margin-top: -50px;
  }
}

@media screen and (max-width: 460px) {
  .left_section_box_error {
    margin-top: -50px;
  }

  .right_section_box_error {
    margin-top: -30px;
  }

  .left_section_box_error h3 {
    font-size: 28px;
  }

  .left_section_box_error p {
    font-size: 20px;
  }
}
