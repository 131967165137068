.carreer_section{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
}

.card_container{
    display: flex ;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px 30px;
    background-color: var(--input-hover);
    border-radius: 50px;
    width: 80%;
    box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.25);
    margin: 0px auto;
}

.card_left img{
    width: 220px;
    height: 220px;
    border-radius: 50%;
    /* margin-left: 50px; */
}

.card_right{
    /* padding-top: 20px; */
    /* margin-left: 10px; */
    padding: 0px 20px;
}

.card_right p{
    font-size : 19px ;
    /* width: 80% ; */
    /* letter-spacing: 0.1px; */
    color:var(--primary-dark) ;
    /* text-align: center;  */
    font-weight: 400; 
}

.apply_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;
}

.apply_btn button{
    background-color: var(--primary-color);
    outline: none; 
    border: none; 
    color: white;
    padding: 8px 30px;
    border-radius: 5px;
    transition: 0.2s ease-in-out; 
    cursor:pointer;
    font-size: 20px;
}

.apply_btn button:hover{
    transform: scale(1.08);
}

@media screen and (max-width : 800px) {
    .card_container{
        flex-direction: column;
    }
    .card_right{
        padding: 0;
    }
}

@media screen and (max-width : 490px) {
    .card_left img{
        width: 180px;
        height: 180px;
        border-radius: 50%;
    }
    .card_right p{
        font-size : 17px ;
        font-weight: 500; 
    }
    .card_right{
        padding: 0;
    }
}

@media screen and (max-width : 420px) {
    .card_left img{
        width: 160px;
        height: 160px;
        border-radius: 50%;
    }
    .card_right p{
        font-size : 16px ;
        font-weight: 500; 
    }
    .card_right{
        padding: 0;
    }
}

@media screen and (max-width : 390px) {
    .card_container{
       padding : 20px 20px ;
    }
    .card_left img{
        width: 140px;
        height: 140px;
        border-radius: 50%;
    }
    .card_right p{
        font-size : 15px ;
        font-weight: 500; 
    }
    .card_right{
        padding: 0;
    }
}

@media screen and (max-width : 326px) {
    .carreer_section{
        font-size: 16px;
        text-align: center;
    }
    .card_container{
       padding : 10px 10px ;
    }
    .card_left img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
    }
    .card_right p{
        font-size : 14px ;
        font-weight: 450; 
    }
    .apply_btn button{
        padding: 5px 25px;
    }
    .card_right{
        padding: 0;
    }
}