.doubt_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 40px;
}

.form_doubt_section {
  text-align: center;
}

.form_doubt_section input,
textarea {
  outline: none;
  margin-bottom: 10px;
  width: 550px;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
  border: 1px solid var(--input-hover);
}

.form_doubt_section input:focus,
textarea:focus {
  outline: 1px solid var(--primary-color);
}

.send_button_doubt_section {
  background-color: var(--primary-color);
  color: white;
  padding: 15px 25px;
  border: none;
  outline: none;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  width: 180px;
  font-size: 18px;
  margin-top: 18px;
}

.send_button_doubt_section:hover {
  transform: scale(1.01);
  background-color: var(--primary-light);
  cursor: pointer;
}

@media screen and (max-width: 490px) {
  .form_doubt_section input,
  textarea {
    width: 400px;
  }
}

@media screen and (max-width: 425px) {
  .form_doubt_section input,
  textarea {
    width: 300px;
  }
  .send_button_doubt_section {
    width: 150px;
    padding: 10px 15px;
  }
}

@media screen and (max-width: 350px) {
  .form_doubt_section input,
  textarea {
    width: 250px;
  }
  .send_button_doubt_section {
    width: 125px;
    padding: 8px 13px;
  }
}
