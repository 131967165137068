.join_section{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 40px;
}

.join_section_card p{
    color: #3f3f3f;
    font-weight: 400;
    padding: 5px 0;

}
.join_section_container{
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-evenly;
}

.join_section_container_card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin-bottom: 50px;
    padding: 1rem;
}

.join_section_container_card:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border: 1px solid var(--primary-color);
}

.join_section_container_card img{
    width: 370px;
    border-radius: 5px 5px 0 0;
}

.join_section_card{
    width : 300px ;
    padding: 2px 16px;
}

@media screen and (max-width : 936px) {
    .join_section_container{
        grid-template-columns: auto auto ;
    }
}

@media screen and (max-width : 650px) {
    .join_section_card{
        width : 250px ;
        padding: 1px 8px;
    }
    .join_section_container_card img{
        width: 250px;
        border-radius: 5px 5px 0 0;
    }
}

@media screen and (max-width : 560px){
    .join_section_card{
        width : 200px ;
        padding: 1px 8px;
    }
    .join_section_container_card img{
        width: 200px;
        border-radius: 5px 5px 0 0;
    }
    .join_section_card p {
        font-size: 14px;
    }
}

@media screen and (max-width : 440px){
    .join_section_container{
        grid-template-columns: auto ;
    }
    .join_section_card{
        width : 300px ;
        padding: 1px 8px;
    }
    .join_section_container_card img{
        width: 300px;
        border-radius: 5px 5px 0 0;
    }
}