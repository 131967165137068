.header_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4% 7%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  overflow-x: hidden;
  background-color: var(--header-bg);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.header_shadow_header {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
}

.left_section_header {
  display: flex;
  align-items: center;
}

.right_section_header {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left_section_header .intern_header {
  margin-left: 2.188rem;
  text-align: center;
  text-decoration: none;
  color: var(--black-color);
  font-weight: 500;
  font-size: 1rem;
  position: relative;
}

.left_section_header .intern_header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: var(--black-color);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.left_section_header .intern_header:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.mid_section_header {
  min-width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mid_section_header ul {
  /* margin-left: 140px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  grid-gap: 30px;
}

.mid_section_header a {
  color: var(--primary-dark);
  margin-left: 2.188rem;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  letter-spacing: 0.2px;
  position: relative;
  text-decoration: none;
}

.mid_section_header a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background-color: var(--black-color);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.mid_section_header a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.mid_section_header a:hover {
  text-decoration: none;
  color: var(--primary-dark);
}

.button_container_header {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
}

.btn_primary_header {
  min-width: 90px;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 9px 14px;
  border-radius: 0.313rem;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  cursor: pointer;
}

.btn_primary_header:hover {
  background-color: var(--primary-light);
}

.btn_light_header {
  min-width: 90px;
  margin-left: 20px;
  background-color: transparent;
  color: var(--primary-color);
  padding: 9px 14px;
  border-radius: 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid var(--primary-color);
  cursor: pointer;
}

.btn_light_header:hover {
  background-color: var(--background-secondary);
}

.theme_icon_container_header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5px 8px 7.5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.theme_icon_container_header:hover {
  background-color: var(--background-secondary-hover);
}

.theme_icon_header {
  font-size: 23px;
  color: var(--primary-color);
}

.left_section_header img {
  margin-top: 8px;
  width: 70px;
}

.intern2_header,
.hamburger_header {
  display: none;
}

.nav ul{
  display: none;
  position: fixed;
  background-color: var(--header-bg);
  width: 120px;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  margin-left: 50px;
  padding: 10px 0;
}

.nav ul li{
  padding: 5px 0;

}

.nav ul li a{
  margin-left: 10px;
}

.nav li:hover ul{
  display: block;
}

/* ANIMATIONS */
#fade-in-top{
  
  animation: fadeAnimationTop 1s ease-in-out;
}
@keyframes fadeAnimationTop {
  from{
    transform: translateY(-100%);
  }
  to{
    transform: translateY(0);
  }
}

@media screen and (max-width: 970px) {
  .mid_section_header a {
    margin-left: 1.188rem;
  }
}

@media screen and (max-width: 870px) {
  .right_section_header {
    width: 83%;
  }
  .header_header {
    padding: 1.2% 5%;
  }

  .left_section_header .intern_header {
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding: 1.5% 9%;
  }

  .intern_header,
  .theme_icon_container_header {
    display: none;
  }

  .intern2_header,
  .hamburger_header {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
  }

  .hamburger_icon_header {
    color: var(--black-color);
  }

  .mid_section_header ul {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .right_section_header {
    position: fixed;
    top: 79px;
    left: 0;
    width: 100vw !important;
    z-index: 99;
    padding: 25px 9% 20px 9%;
    display: none;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    transition: 0.3s all ease;
  }

  .right_section_header.active_header {
    display: flex;
    background-color: var(--header-bg);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
  }

  .button_container_header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
  }

  .btn_primary_header {
    width: 100%;
  }

  .btn_light_header {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .right_section_header {
    top: 76px;
  }

  .theme_icon2_header {
    font-size: 18px;
  }

  .hamburger_header {
    grid-gap: 10px;
  }
}

@media screen and (max-width: 510px) {
  .right_section_header {
    top: 64px;
  }

  .left_section_header img {
    width: 60px;
  }
  .nav ul{
  margin-left: 85px;
  margin-top: -20px;
  }
}
