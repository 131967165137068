.about_banner_second_container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  padding: 100px 0;
  background-color: var(--background-secondary);
  grid-gap: 50px;
}

.main_content_banner {
  width: 50%;
}

.about_second_banner_section {
  width: 25%;
}

.main_heading {
  color: var(--primary-color);
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: 600;
}

.second_banner_content {
  font-size: 20px;
  font-weight: 400;
  color: #3f3f3f;
}

.text{
    padding: 0;
}
.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

.about_banner_second_container img {
  width: 300px;
  height: 250px;
}

/* ANIMATIONS */
#fade-in-left{
  opacity: 0;
  left: 0;
  animation: fadeInAnimationLeft 1s ease 1s forwards;
}
@keyframes fadeInAnimationLeft{
  from{
    opacity: 0;
    transform: translateX(-1000px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
}

#fade-in-right{
  opacity: 0;
  right: 0;
  animation: fadeInAnimationRight 1s ease 0.5s forwards;
}
@keyframes fadeInAnimationRight{
  from{
    opacity: 0;
    transform: translateX(1000px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 900px) {
  .about_banner_second_container {
    flex-direction: column;
  }
  .main_content_banner {
    width: 100%;
    margin-bottom: 30px;
  }
  .second_banner_content {
    font-size: 16px;
    font-weight: 400;
    /* text-align: center; */
    padding: 0px 70px;
  }
  .main_heading {
    text-align: center;
  }
  .about_second_banner_section {
    width: 100%;
    text-align: center;
  }
  .about_banner_second_container img {
    width: 285px;
    height: 235px;
  }
}

@media screen and (max-width: 550px) {
  .main_heading {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }
  .about_banner_second_container img {
    width: 265px;
    height: 215px;
  }
}

@media screen and (max-width: 485px) {
  .main_heading {
    font-size: 34px;
    font-weight: 600;
    text-align: center;
  }
  .about_banner_second_container img {
    width: 265px;
    height: 215px;
  }
  .second_banner_content {
    font-size: 16px;
    font-weight: 400;
    /* text-align: center; */
    padding: 0px 50px;
  }
}

@media screen and (max-width: 485px) {
  .main_heading {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }
  .about_banner_second_container img {
    width: 245px;
    height: 195px;
  }
  .second_banner_content {
    font-size: 16px;
    font-weight: 400;
    /* text-align: center; */
  }
}

@media screen and (max-width: 360px) {
  .main_heading {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .about_banner_second_container img {
    width: 185px;
    height: 155px;
  }
  .second_banner_content {
    font-size: 15px;
    font-weight: 300;
    /* text-align: center; */
  }
}
